// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-category-index-js": () => import("../src/templates/category/index.js" /* webpackChunkName: "component---src-templates-category-index-js" */),
  "component---src-templates-drop-dates-index-js": () => import("../src/templates/drop-dates/index.js" /* webpackChunkName: "component---src-templates-drop-dates-index-js" */),
  "component---src-templates-item-index-js": () => import("../src/templates/item/index.js" /* webpackChunkName: "component---src-templates-item-index-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

